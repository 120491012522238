import React from 'react'
import { useTranslation } from 'react-i18next'
import { SvgIcon, Typography, useMediaQuery } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Logo from '../../svg/logo/IconBoxed'
import TextLogo from '../../svg/TextLogo'
import AppleIcon from '@mui/icons-material/Apple'
import AndroidIcon from '../../svg/AndroidIcon'
import CreateButton from 'components/Navigation/CreateButton'
import { mdiInstagram, mdiLinkedin, mdiWhatsapp } from '@mdi/js'
import { useIsLoggedIn } from 'utils/hooks'
import Link from 'next/link'
import { useSelector } from 'react-redux'

const useStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(6),
    maxWidth: theme.breakpoints.values.containerMaxWidth,
    width: '100%',
    borderTop: `1px solid ${theme.palette.divider}`,
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
  },
  rootMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2.5),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  logo: {
    width: 14.44,
    height: 15,
    color: theme.palette.grey[700],
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  textLogo: {
    height: 13.52,
    color: theme.palette.grey[700],
  },
  button: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 8),
    height: 48,
    backgroundColor: theme.palette.secondary.main,
  },
  text: {
    textAlign: 'center',
    color: theme.palette.grey[700],
  },
  downloadText: {
    color: theme.palette.grey[700],
  },
  storeButtonsContainer: {
    fill: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  },
  storeButtonsDivider: {
    height: 14,
    width: 1,
    borderRadius: '100px',
    backgroundColor: theme.palette.grey[600],
  },
  linksContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
    color: theme.palette.text.tertiary,
    '& a': {
      textDecoration: 'underline',
    },
    flexWrap: 'wrap',
  },
  createButton: {},
  storeButtonsIcon: {
    fill: theme.palette.grey[600],
    width: 14,
    height: 14,
    cursor: 'pointer',
  },
  copyRightContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(1.5),
  },
  copyRightContainerMobile: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
  leftContainerMobile: {
    alignItems: 'center',
  },
  socialsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  },
  socialsIcon: {
    width: 18,
    height: 18,
    color: theme.palette.grey[700],
  },
  tiktokIcon: {
    width: 14,
    height: 14,
    color: theme.palette.grey[700],
  },
  darkGrey: {
    color: theme.palette.grey[700],
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: theme.spacing(1.5),
  },
  rightContainerMobile: {
    alignItems: 'center',
  },
}))

const GeneralFooter = ({ maxWidth, hideBorder = false }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')

  const desktopMode = useMediaQuery('(min-width:900px)')
  const isMobileApp = useSelector((state) => state.user.isMobileApp)

  const isLoggedIn = useIsLoggedIn()
  const androidClicked = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=com.vlinderstorm.bash',
    )
  }
  const appleClicked = () => {
    window.open('https://apps.apple.com/app/bash-sociale-events/id1448137786')
  }

  const copyRight = () => {
    return (
      <div
        className={cx(
          classes.copyRightContainer,
          !desktopMode && classes.copyRightContainerMobile,
        )}
      >
        <Typography variant='caption' className={classes.text}>
          © BASH copyright and trademark 2025
        </Typography>
        <div className={classes.storeButtonsContainer}>
          <AppleIcon
            className={classes.storeButtonsIcon}
            onClick={appleClicked}
          />
          <div className={classes.storeButtonsDivider} />
          <AndroidIcon
            className={classes.storeButtonsIcon}
            onClick={androidClicked}
          />
        </div>
      </div>
    )
  }

  const showSupport = () => {
    window.open('https://wa.me/message/4GG2EGM4GIDBD1')
  }

  if (isMobileApp) return null

  return (
    <div className={classes.container}>
      <div
        className={cx(classes.root, !desktopMode && classes.rootMobile)}
        style={{
          maxWidth,
          borderTop: hideBorder ? 'none' : undefined,
        }}
      >
        <div
          className={cx(
            classes.leftContainer,
            !desktopMode && classes.leftContainerMobile,
          )}
        >
          <a href='/'>
            <div className={classes.logoContainer}>
              <Logo className={classes.logo} />
              <TextLogo className={classes.textLogo} />
            </div>
          </a>

          <Typography variant='caption' className={classes.downloadText}>
            Where hype starts
          </Typography>
          {desktopMode && copyRight()}
        </div>

        {!desktopMode && (
          <CreateButton fancy className={classes.createButton} />
        )}

        <div
          className={cx(
            classes.rightContainer,
            !desktopMode && classes.rightContainerMobile,
          )}
        >
          <div className={classes.socialsContainer}>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.instagram.com/bash.social/'
              aria-label='Instagram'
            >
              <SvgIcon className={classes.socialsIcon}>
                <path d={mdiInstagram} />
              </SvgIcon>
            </a>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.tiktok.com/@bash.social'
              aria-label='Tiktok'
            >
              <SvgIcon className={classes.socialsIcon}>
                <path
                  d={
                    'M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z'
                  }
                />
              </SvgIcon>
            </a>

            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://linkedin.com/company/bashsocial'
              aria-label='Linkedin'
            >
              <SvgIcon className={classes.socialsIcon}>
                <path d={mdiLinkedin} />
              </SvgIcon>
            </a>

            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://wa.me/message/4GG2EGM4GIDBD1'
              aria-label='Whatsapp'
            >
              <SvgIcon className={classes.socialsIcon}>
                <path d={mdiWhatsapp} />
              </SvgIcon>
            </a>
          </div>
          <div className={cx(classes.linksContainer, classes.darkGrey)}>
            {desktopMode && (
              <CreateButton fancy className={classes.createButton} />
            )}
            {isLoggedIn && (
              <Typography variant='caption'>
                <Link aria-label='My events' href='/home'>
                  My events
                </Link>
              </Typography>
            )}
            {isLoggedIn && (
              <Typography variant='caption'>
                <Link aria-label='For you' href='/foryou'>
                  For you
                </Link>
              </Typography>
            )}
            {!isLoggedIn && (
              <Typography variant='caption'>
                <Link aria-label='Hot events' href='/foryou'>
                  Hot events
                </Link>
              </Typography>
            )}
            {!isLoggedIn && (
              <Typography variant='caption'>
                <Link aria-label='Sign in' href='/signIn'>
                  {t('signIn')}
                </Link>
              </Typography>
            )}
          </div>
          <div className={classes.linksContainer}>
            {/* <Typography variant='caption'>
              <Link href='/about'>{t('aboutBash')}</Link>
            </Typography> */}
            <Typography variant='caption'>
              <Link aria-label='Jobs' href='/jobs'>
                Jobs
              </Link>
            </Typography>
            {/*<Typography variant='caption'>*/}
            {/*  <Link href='/pricing'>Pricing</Link>*/}
            {/*</Typography>*/}
            <Typography variant='caption'>
              <Link aria-label='Privacy' href='/privacy'>
                Privacy
              </Link>
            </Typography>
            <Typography variant='caption'>
              <Link aria-label='Terms' href='/terms'>
                Terms & conditions
              </Link>
            </Typography>
            <Typography variant='caption'>
              <div
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                aria-label='Support'
                onClick={() => showSupport()}
              >
                Support
              </div>
            </Typography>
          </div>

          {!desktopMode && copyRight()}
        </div>
      </div>
    </div>
  )
}
export default GeneralFooter
